<template>
  <v-container class="bg-glass-effect py-0 px-0" fluid>
    <BackArrow class="" v-if="isLoadedBeneficiary"/>

    <v-row v-if="!isLoadedBeneficiary" justify="center" style="height: 100%">
      <v-col cols="6" align="center" align-self="center">

      </v-col>
    </v-row>

    <v-row v-else class="px-3 my-10">
      <v-col class="pb-0 pt-4 mx-3" cols="12">
        <h2 class="px-0 mx-0" style="color: #292867">Carteirinhas</h2>
      </v-col>
      <v-col>
        <v-row class="px-3 py-0 my-0" v-for="(beneficiary, index) in beneficiaries" :key="index">
          <v-col class="benefit-name px-3" cols="12" style="margin-bottom: 150px; margin-top: 10px;">
            <p>{{beneficiaries[index][0].benefitName}}</p>
          </v-col>
          <v-col class="pa-0 px-3 rounded-b-xl" cols="12" v-for="(card, auxIndex) in beneficiary" :key="auxIndex">
            <v-card class="card-sheet" :elevation="8" rounded="xl" style="margin-top: -150px"
                    :style="selectBackgroundCard(card.cardImage)" @click="clickCard(card)">
              <v-card-text class="py-0 my-0">
                <v-row class="px-3">
                  <v-col class="px-0 py-3" cols="12">
                    <div class="row" v-html="card.cardProcessTemplate ? card.cardProcessTemplate : cardTemplateIfNotExist"></div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import BackArrow from '@/components/covid/back-arrow/index'
  import { mapMutations } from "vuex";
  export default {
    name: "walletPrincipal",
    data: () => ({
      beneficiaries: [],
      cardTemplateIfNotExist: '<p style=\'text-align:center; color:white\'>Não foi possível carregar os dados da carteirinha</p>',
      isLoadedBeneficiary: false
    }),
    components: {
      BackArrow,
    },
    async created () {
      this.loading(true);
      const beneficiariesGroupBy = this.groupBy('benefit')
      this.beneficiaries = beneficiariesGroupBy(this.$auth.getBeneficiaryCards())
      this.beneficiaries = Object.values(this.beneficiaries).reverse();
      this.isLoadedBeneficiary = true
      this.loading(false);
    },
    methods: {
      ...mapMutations(["loading"]),
      groupBy(key) {
        return function group(array) {
          return array.reduce((acc, obj) => {
            const property = obj[key];
            acc[property] = acc[property] || [];
            acc[property].push(obj);
            return acc;
          }, {});
        };
      },
      selectBackgroundCard(cardImage) {
        if(cardImage){
          return { backgroundImage: 'url(\'data:image;base64,'+cardImage+'\')', backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat', backgroundColor: 'transparent'}
        } else {
          return { background: 'linear-gradient(135deg, rgba(41,40,103,1) 50%, rgba(92,90,131,1) 100%)' }
        }
      },
      clickCard(card) {
        let cards = []
        card.dependents.forEach(c => {
          cards.push(c)
        })
        delete card.dependents
        cards.unshift(card)
        this.$router.push({name: 'walletDetailCards', params: {cards: cards, style: this.selectBackgroundCard(card.cardImage)} })
      }
    }
  }
</script>

<style scoped>
/*background effect blur*/
.bg-glass-effect{
  background: rgba(255,255,255,.8) !important;
  mix-blend-mode: normal !important;
  backdrop-filter: blur(100px) !important;
  height: 100vh;
}

.benefit-name {
  color: #333333;
  font-weight: bold;
}

.card-sheet {
  width: 275px;
  height: 210px;
  overflow: visible;
}
</style>


